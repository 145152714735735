<template>
  <div>
    <el-dialog
      :title="$t('addLogin.register.message3')"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="30%"
      @close="closeDialog"
      style="margin-top: 10vh"
      :modal-append-to-body="false"
    >
      <div style="display: flex; align-items: center">
        <div style="min-width: 115px; text-align: left">
          {{ $t("addLogin.register.mobilePhoneNumber") }}
        </div>
        <el-select
          v-model="currentArea"
          filterable
          @change="handleChangePhone"
          size="max"
        >
          <el-option
            v-for="(item, index) in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
        <el-input
          style="margin-left: 10px"
          v-model="phone"
          type="text"
          :placeholder="$t('addLogin.loginr.phone')"
        ></el-input>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px">
        <div style="min-width: 115px; text-align: left">
          {{ $t("addLogin.register.verificationCode") }}
        </div>
        <el-input
          id="code"
          v-model="phoneVerifyCode"
          autoComplete="false"
          type="text"
          :placeholder="$t('addLogin.register.VerificationCodeSentToCellPhone')"
          style="margin-right: 10px"
        ></el-input>
        <el-button v-if="!isPhoneSend" type="primary" @click="sendPhoneCode">
          <p style="white-space: nowrap; font-size: 14px; margin: 6px 0">
            {{ $t("addLogin.forgetPsd.getCode") }}
          </p>
        </el-button>
        <el-button type="primary" v-else class="yzm1">
          <p id="yzm2" style="white-space: nowrap; margin: 6px 0">
            {{ $t("addLogin.forgetPsd.message6") }}
          </p>
        </el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $t("btn.cancelBtn") }}</el-button>
        <el-button type="primary" @click="handleLogin">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <button v-show="false" id="getcodephoto"></button>
    <div id="space-semantic">
      <div id="embed-wrapper">
        <div class="embed-wrapper">
          <div id="captcha-element"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPhoneVerifyCode } from "@/network/login/index.js";
import { metadata, PhoneNumberUtil } from "google-libphonenumber";
import { supplementPhone } from "@/network/login";
export default {
  props: {
    // 1.弹窗是否显示
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lock: false,
      captcha: null,
      resultPhone: "",
      code: "",
      phoneVerifyCode: "",
      phone: "",
      issend: false,
      isPhoneSend: false,
      timeStop: "",
      phoneTimeStop: "",
      verify5Config: {
        name: "v5",
        host: "freetvks2vi2.verify5.com",
        token: "61455cc20ef94a91addae923736105e4",
      },
      currentArea: this.$t("countryPhoneDefault"),
      options: this.$t("countryPhoneList"),
      countryCodePhone: "86",
      captchaButton: null,
    };
  },
  // watch: {
  //   dialogVisible () {
  //     if(this.dialogVisible) {
  //       this.getGraphicverificationcode();
  //     }
  //   }
  // },
  beforeMount() {
    this.captchaButton = null;
    // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
    document.getElementById("aliyunCaptcha-mask")?.remove();
    document.getElementById("aliyunCaptcha-window-popup")?.remove();
  },
  mounted() {
    this.getGraphicverificationcode();
  },
  beforeDestroy() {
    clearInterval(this.timeStop);
  },
  methods: {
    // 取消弹窗
    closeDialog() {
      // this.dialogVisible = false;
      this.phone = "";
      this.phoneVerifyCode = "";
      this.$emit("cancelDialog", 'noupdate');
    },
    // 手机号写入
    handleLogin() {
      const self = this;
      const phonereally = this.deletePhonezero();
      if (self.phoneVerifyCode.length < 1) {
        self.$message.warning(self.$t("addLogin.forgetPsd.place3"));
        return false;
      }
      let data = {
        phone: self.countryCodePhone + phonereally,
        verifyCode: self.phoneVerifyCode,
      };
      supplementPhone(data).then((res) => {
        this.$emit("cancelDialog", 'update');
      });
    },
    handleChangePhone(e) {
      const arr = e.split(" ");
      const list = arr[0].split("+");
      this.countryCodePhone = list[1];
      this.currentArea = arr[0];
    },
    deletePhonezero() {
      const str = this.phone;
      if (str.charAt(0) === "0") {
        return str.substring(1);
      }
      return str;
    },
    // 业务请求(带验证码校验)回调函数
    async captchaVerifyCallback(captchaVerifyParam) {
      const self = this;
      const phonereally = this.deletePhonezero();
      // 2.构造标准返回参数
      const params = {
        phone: phonereally,
        countryCode: self.countryCodePhone,
        verifyReqDto: {
          param: captchaVerifyParam,
        },
      };
      try {
        await requestPhoneVerifyCode(params).then((res) => {
          this.resultPhone = res.businessError;
        });
      } catch (error) {
        console.log(error);
      }
      if (this.resultPhone == null) {
        const verifyResult = {
          captchaResult: true, // 验证码验证是否通过，boolean类型，必选
          bizResult: true,
        };
        return verifyResult;
      } else if (this.resultPhone == "CAPTCHA_NOT_CORRECT") {
        const verifyResult = {
          captchaResult: false,
          bizResult: false,
        };
        return verifyResult;
      } else {
        const verifyResult = {
          captchaResult: true,
          bizResult: false,
        };
        return verifyResult;
      }
    },
    // 业务请求验证结果回调函数
    onBizResultCallback(bizResult) {
      const self = this;
      if (bizResult === true) {
        // 如果业务验证通过
        if (this.resultPhone == null) {
          self.$message({
            type: "success",
            message: self.$t("addLogin.register.VerificationCodeSent"),
            duration: "5000",
          });
          self.isPhoneSend = true;
          let timeo = 180;
          self.phoneTimeStop = setInterval(() => {
            timeo--;
            if (timeo > 0) {
              document.getElementById("yzm2").innerText =
                self.$t("addLogin.forgetPsd.message8") +
                timeo +
                self.$t("addLogin.forgetPsd.message9");
            } else {
              timeo = 180;
              self.isPhoneSend = false;
              document.getElementById("yzm2").innerText = this.$t(
                "addLogin.forgetPsd.getCode"
              );
              clearInterval(self.phoneTimeStop);
            }
          }, 1000);
        }
      } else {
        // 如果业务验证不通过
        if (this.resultPhone == "USER_OPERATION_FREQUENCY_IS_TOO_FAST") {
          self.$message({
            type: "error",
            message: self.$t("addLogin.register.OperateFrequentPrompts"),
            duration: "5000",
          });
        }
      }
    },
    getGraphicverificationcode() {
      this.captchaButton = document.getElementById("getcodephoto");
      // 弹出式;
      initAliyunCaptcha({
        SceneId: "bcuib0pi", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: "1vtf3d", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: "#captcha-element", //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: "#getcodephoto", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });
    },
    // 绑定验证码实例函数。该函数为固定写法，无需修改
    getInstance(instance) {
      this.captcha = instance;
    },
    async sendPhoneCode() {
      const self = this;
      self.lock = true;
      if (self.phone.length < 1) {
        this.captcha.hide();
        self.$message.warning(
          self.$t("addLogin.register.PleaseEnterYourCellPhoneNumber")
        );
        self.lock = false;
        return false;
      }
      if (self.check_phone()) {
        this.captcha.show();
      } else {
        this.captcha.hide();
        self.lock = false;
        self.$message.warning(
          self.$t("addLogin.register.PleaseEnterTheCorrectCellPhoneNumber")
        );
      }
    },
    check_phone() {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(
        this.phone,
        metadata.countryCodeToRegionCodeMap[this.countryCodePhone][0]
      );
      const isValite = phoneUtil.isValidNumber(number);
      return isValite;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding-top: 30px !important;
}
</style>
