import request from "@/utils/request"
import serviceConfig from '@/network/serviceConfig'
// 获取基础软件信息
export function get_basic_license() {
  return request({
    url: `/${serviceConfig['account-service']}/tenantLicense`,
    method: "get",
  })
}

// 获取收费组件信息
export function get_component_license() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/payFeatures`,
    method: "get",
  })
}